<template>
  <v-bottom-navigation
    class="overflow-hidden"
    :fixed="fixed"
    grow
    color="primary"
  >
    <v-btn :to="toHome()">
      <span class="my-1">Home</span>
      <v-icon size="18">mdi-home</v-icon>
    </v-btn>

    <v-btn to="/whatsnew">
      <span class="my-1">News</span>
      <v-icon style="color: #cbcbcb" size="18">mdi-email</v-icon>
    </v-btn>
    <v-btn to="/">
      <span class="my-1">Services</span>
      <v-icon style="color: #cbcbcb" size="18">mdi-view-grid</v-icon>
    </v-btn>

    <v-btn :href="getWhatsAppUrl() + profile.contact_phone" target="_blank">
      <span class="my-1">Chat</span>

      <v-icon size="18" color="#CBCBCB">mdi-whatsapp</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: ['fixed'],
  computed: {
    ...mapState('merchant', {
      profile: (state) => state.profile,
    }),
    ...mapGetters('cart', {
      isDelivery: 'isDelivery',
      isPickup: 'isPickup',
      isDinein: 'isDinein',
    }),
  },
  methods: {
    toHome() {
      if (this.isDinein) {
        if (this.$cookies.get('table-no')) {
          return `/dinein/${this.$cookies.get('table-no')}`;
        } else {
          return `/scan`;
        }
      } else {
        return `/menu`;
      }
    },
    getWhatsAppUrl() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? 'https://api.whatsapp.com/send?phone='
        : 'https://web.whatsapp.com/send?phone=';
    },
  },
};
</script>

<style>
@media screen and (max-width: 320px) {
  .v-bottom-navigation .v-btn i {
    font-size: 20px;
  }
  .v-bottom-navigation .v-btn .v-btn__content {
    font-size: 10px;
  }
}
</style>
